import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

type FILE_OPTIONS = 'cancelarFacturas'|'cancelarGlobal'|'cancelar'|'clientes'|'pedimentosNew'|'pedimentos'|'productMultipleSAT'|'productSAT'|'productIEPS'|'productTax'|'orderTax'|'settupTaxes'|'billRefundComplete'|'billRefund'|'billGlobalConfigure'|'billGlobalComplete'|'billGlobalStart'|'billMultipleComplete'|'billMultiple'|'billComplete'|'billStartPedidos'|'billStartOrder'|'onBillsReport'|'onBills'|'onCheckout' | 'certificates' | 'clavesPredet' | 'paginaFact' | 'paginaAdd' | 'paginaWrite' | 'paginaHtml' | 'paginaHtmlEdit' | 'paginaHtmlPreview';

export const IMG_Shopify: React.FC<{ file: FILE_OPTIONS }> = ({ file }) => {
  const data = useStaticQuery(graphql`
    query {
      certificates: file(relativePath: { eq: "docimages/shopify_certificates.png" }) {
        childImageSharp {
          fixed (width:900){
            ...GatsbyImageSharpFixed
          }
        }
      }
      clavesPredet: file(relativePath: { eq: "docimages/shopify_clavespredeterminadas.png" }) {
        childImageSharp {
          fixed (width:900){
            ...GatsbyImageSharpFixed
          }
        }
      }
      paginaFact: file(relativePath: { eq: "docimages/shopify_paginafact.png" }) {
        childImageSharp {
          fixed (width:900){
            ...GatsbyImageSharpFixed
          }
        }
      }
      paginaAdd: file(relativePath: { eq: "docimages/shopify_pages.png" }) {
        childImageSharp {
          fixed (height:500){
            ...GatsbyImageSharpFixed
          }
        }
      }
      paginaWrite: file(relativePath: { eq: "docimages/shopify_pages_write.png" }) {
        childImageSharp {
          fixed (height:500){
            ...GatsbyImageSharpFixed
          }
        }
      }
      paginaHtml: file(relativePath: { eq: "docimages/shopify_pages_html.png" }) {
        childImageSharp {
          fixed (height:500){
            ...GatsbyImageSharpFixed
          }
        }
      }
      paginaHtmlEdit: file(relativePath: { eq: "docimages/shopify_pages_html_edit.png" }) {
        childImageSharp {
          fixed (height:500){
            ...GatsbyImageSharpFixed
          }
        }
      }
      paginaHtmlPreview: file(relativePath: { eq: "docimages/shopify_pages_html_preview1.png" }) {
        childImageSharp {
          fixed (height:500){
            ...GatsbyImageSharpFixed
          }
        }
      }
      onCheckout: file(relativePath: { eq: "docimages/shopify_oncheckout.png" }) {
        childImageSharp {
          fixed (height:550){
            ...GatsbyImageSharpFixed
          }
        }
      }
      onBills: file(relativePath: { eq: "docimages/shopify_bills.png" }) {
        childImageSharp {
          fixed (height:550){
            ...GatsbyImageSharpFixed
          }
        }
      }
      onBillsReport: file(relativePath: { eq: "docimages/shopify_onBillsReport.png" }) {
        childImageSharp {
          fixed (height:550){
            ...GatsbyImageSharpFixed
          }
        }
      }
      billStartOrder: file(relativePath: { eq: "docimages/shopify_billStartOrder.png" }) {
        childImageSharp {
          fixed (width:550){
            ...GatsbyImageSharpFixed
          }
        }
      }
      billStartPedidos: file(relativePath: { eq: "docimages/shopify_billStartPedidos.png" }) {
        childImageSharp {
          fixed (width:550){
            ...GatsbyImageSharpFixed
          }
        }
      }
      billComplete: file(relativePath: { eq: "docimages/shopify_billComplete.png" }) {
        childImageSharp {
          fixed (width:850){
            ...GatsbyImageSharpFixed
          }
        }
      }
      billMultiple: file(relativePath: { eq: "docimages/shopify_multiplebilling.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      billMultipleComplete: file(relativePath: { eq: "docimages/shopify_multiBilling.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      billGlobalStart: file(relativePath: { eq: "docimages/shopify_globalFirst.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      billGlobalComplete: file(relativePath: { eq: "docimages/shopify_billGlobalComplete.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      billGlobalConfigure: file(relativePath: { eq: "docimages/shopify_globalconfigure.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      billRefund: file(relativePath: { eq: "docimages/shopify_refund.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      billRefundComplete: file(relativePath: { eq: "docimages/shopify_refundComplete.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      settupTaxes: file(relativePath: { eq: "docimages/shopify_Taxes.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      orderTax: file(relativePath: { eq: "docimages/shopify_orderTax.png" }) {
        childImageSharp {
          fixed (width:650){
            ...GatsbyImageSharpFixed
          }
        }
      }
      productTax: file(relativePath: { eq: "docimages/shopify_productTax.png" }) {
        childImageSharp {
          fixed (width:650){
            ...GatsbyImageSharpFixed
          }
        }
      }
      productIEPS: file(relativePath: { eq: "docimages/shopify_IEPS.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      productSAT: file(relativePath: { eq: "docimages/shopify_SATEdit.png" }) {
        childImageSharp {
          fixed (width:650){
            ...GatsbyImageSharpFixed
          }
        }
      }
      productMultipleSAT: file(relativePath: { eq: "docimages/shopify_editMany.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      pedimentos: file(relativePath: { eq: "docimages/shopify_pedimentos.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      pedimentosNew: file(relativePath: { eq: "docimages/shopify_pedimentoNew.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      clientes: file(relativePath: { eq: "docimages/shopify_Clientes.png" }) {
        childImageSharp {
          fixed (width:950){
            ...GatsbyImageSharpFixed
          }
        }
      }
      cancelar: file(relativePath: { eq: "docimages/shopify_cancelar.png" }) {
        childImageSharp {
          fixed (width:600){
            ...GatsbyImageSharpFixed
          }
        }
      }
      cancelarGlobal: file(relativePath: { eq: "docimages/shopify_cancelarGlobal.png" }) {
        childImageSharp {
          fixed (width:600){
            ...GatsbyImageSharpFixed
          }
        }
      }
      cancelarFacturas: file(relativePath: { eq: "docimages/shopify_cancelFacturas.png" }) {
        childImageSharp {
          fixed (width:600){
            ...GatsbyImageSharpFixed
          }
        }
      }

    }
  `)
  if (file === 'certificates') {
    return (
      <Img
        fixed={data.certificates.childImageSharp.fixed}
        alt="Configurar certificados"
      />
    )
  } else if (file === 'clavesPredet') {
    return (
      <Img
        fixed={data.clavesPredet.childImageSharp.fixed}
        alt="Configurar claves predeterminadas"
      />
    )
  }
  else if (file === 'paginaFact') {
    return (
      <Img
        fixed={data.paginaFact.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'paginaAdd') {
    return (
      <Img
        fixed={data.paginaAdd.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'paginaWrite') {
    return (
      <Img
        fixed={data.paginaWrite.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'paginaHtml') {
    return (
      <Img
        fixed={data.paginaHtml.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'paginaHtmlEdit') {
    return (
      <Img
        fixed={data.paginaHtmlEdit.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'paginaHtmlPreview') {
    return (
      <Img
        fixed={data.paginaHtmlPreview.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'onCheckout') {
    return (
      <Img
        fixed={data.onCheckout.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'onBills') {
    return (
      <Img
        fixed={data.onBills.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'onBillsReport') {
    return (
      <Img
        fixed={data.onBillsReport.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'billStartOrder') {
    return (
      <Img
        fixed={data.billStartOrder.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'billStartPedidos') {
    return (
      <Img
        fixed={data.billStartPedidos.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'billComplete') {
    return (
      <Img
        fixed={data.billComplete.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'billMultiple') {
    return (
      <Img
        fixed={data.billMultiple.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'billMultipleComplete') {
    return (
      <Img
        fixed={data.billMultipleComplete.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'billGlobalStart') {
    return (
      <Img
        fixed={data.billGlobalStart.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'billGlobalComplete') {
    return (
      <Img
        fixed={data.billGlobalComplete.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'billGlobalConfigure') {
    return (
      <Img
        fixed={data.billGlobalConfigure.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'billRefund') {
    return (
      <Img
        fixed={data.billRefund.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'billRefundComplete') {
    return (
      <Img
        fixed={data.billRefundComplete.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'settupTaxes') {
    return (
      <Img
        fixed={data.settupTaxes.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'orderTax') {
    return (
      <Img
        fixed={data.orderTax.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'productTax') {
    return (
      <Img
        fixed={data.productTax.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'productIEPS') {
    return (
      <Img
        fixed={data.productIEPS.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'productSAT') {
    return (
      <Img
        fixed={data.productSAT.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'productMultipleSAT') {
    return (
      <Img
        fixed={data.productMultipleSAT.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'pedimentos') {
    return (
      <Img
        fixed={data.pedimentos.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'pedimentosNew') {
    return (
      <Img
        fixed={data.pedimentosNew.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'clientes') {
    return (
      <Img
        fixed={data.clientes.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'cancelar') {
    return (
      <Img
        fixed={data.cancelar.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'cancelarGlobal') {
    return (
      <Img
        fixed={data.cancelarGlobal.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
  else if (file === 'cancelarFacturas') {
    return (
      <Img
        fixed={data.cancelarFacturas.childImageSharp.fixed}
        alt="Agregar pagina de facturación"
      />
    )
  }
}
