import React from 'react';

const ShopifyLogo = () => {
    return (
        <svg version="1.1" id="_logoShopify" x="0px" y="0px" viewBox="0 0 500 142.8" height="38">
            <g>
                <g>
                    <path className="st0" d="M107.4,27.1c-0.1-0.7-0.7-1.1-1.2-1.1c-0.5,0-10.4-0.2-10.4-0.2s-8.3-8-9.1-8.9c-0.8-0.8-2.4-0.6-3-0.4
			c0,0-1.6,0.5-4.2,1.3c-0.4-1.4-1.1-3.1-2-4.9c-2.9-5.6-7.3-8.6-12.5-8.6c0,0,0,0,0,0c-0.4,0-0.7,0-1.1,0.1
			c-0.2-0.2-0.3-0.4-0.5-0.5c-2.3-2.4-5.2-3.6-8.7-3.5c-6.7,0.2-13.4,5.1-18.9,13.7c-3.8,6.1-6.7,13.7-7.6,19.6
			c-7.7,2.4-13.1,4.1-13.3,4.1c-3.9,1.2-4,1.3-4.5,5C10.2,45.6,0,124.5,0,124.5l85.6,14.8l37.1-9.2
			C122.6,130.1,107.5,27.8,107.4,27.1z M75.2,19.2c-2,0.6-4.2,1.3-6.6,2.1c0-3.4-0.5-8.2-2-12.2C71.7,9.9,74.2,15.7,75.2,19.2z
			 M64.1,22.6c-4.5,1.4-9.4,2.9-14.3,4.4c1.4-5.3,4-10.5,7.2-14c1.2-1.3,2.9-2.7,4.8-3.5C63.8,13.4,64.2,18.9,64.1,22.6z M54.9,4.9
			c1.6,0,2.9,0.3,4,1.1c-1.8,0.9-3.6,2.3-5.2,4.1c-4.3,4.6-7.6,11.7-8.9,18.6c-4.1,1.3-8.1,2.5-11.7,3.6
			C35.5,21.4,44.6,5.2,54.9,4.9z"></path>
                    <g>
                        <path className="st1" d="M106.2,26c-0.5,0-10.4-0.2-10.4-0.2s-8.3-8-9.1-8.9c-0.3-0.3-0.7-0.5-1.1-0.5l0,122.9l37.1-9.2
				c0,0-15.1-102.3-15.2-103C107.3,26.4,106.7,26,106.2,26z"></path>
                        <path className="st2" d="M65,45.1l-4.3,16.1c0,0-4.8-2.2-10.5-1.8c-8.4,0.5-8.4,5.8-8.4,7.1c0.5,7.2,19.4,8.8,20.5,25.7
				c0.8,13.3-7,22.4-18.4,23.1C30.3,116,22.8,108,22.8,108l2.9-12.3c0,0,7.6,5.7,13.6,5.3c3.9-0.2,5.4-3.5,5.2-5.7
				c-0.6-9.4-16-8.8-17-24.3C26.7,58,35.2,44.9,54,43.7C61.3,43.2,65,45.1,65,45.1z"></path>
                    </g>
                </g>
                <g>
                    <path id="s_4_" d="M172.9,79.4c-4.3-2.3-6.5-4.3-6.5-7c0-3.4,3.1-5.6,7.9-5.6c5.6,0,10.6,2.3,10.6,2.3l3.9-12
			c0,0-3.6-2.8-14.2-2.8c-14.8,0-25.1,8.5-25.1,20.4c0,6.8,4.8,11.9,11.2,15.6c5.2,2.9,7,5,7,8.1c0,3.2-2.6,5.8-7.4,5.8
			c-7.1,0-13.9-3.7-13.9-3.7l-4.2,12c0,0,6.2,4.2,16.7,4.2c15.2,0,26.2-7.5,26.2-21C185,88.4,179.5,83.2,172.9,79.4z"></path>
                    <path id="h_4_" d="M233.5,54.1c-7.5,0-13.4,3.6-17.9,9l-0.2-0.1l6.5-34h-16.9l-16.5,86.6h16.9l5.6-29.6c2.2-11.2,8-18.1,13.4-18.1
			c3.8,0,5.3,2.6,5.3,6.3c0,2.3-0.2,5.2-0.7,7.5l-6.4,33.9h16.9l6.6-35c0.7-3.7,1.2-8.1,1.2-11.1C247.4,59.9,242.4,54.1,233.5,54.1z
			"></path>
                    <path id="o_4_" d="M285.7,54.1c-20.4,0-33.9,18.4-33.9,38.9c0,13.1,8.1,23.7,23.3,23.7c20,0,33.5-17.9,33.5-38.9
			C308.7,65.7,301.6,54.1,285.7,54.1z M277.4,103.8c-5.8,0-8.2-4.9-8.2-11.1c0-9.7,5-25.5,14.2-25.5c6,0,8,5.2,8,10.2
			C291.4,87.8,286.3,103.8,277.4,103.8z"></path>
                    <path id="p_4_" d="M352,54.1c-11.4,0-17.9,10.1-17.9,10.1h-0.2l1-9.1h-15c-0.7,6.1-2.1,15.5-3.4,22.5l-11.8,62h16.9l4.7-25.1h0.4
			c0,0,3.5,2.2,9.9,2.2c19.9,0,32.9-20.4,32.9-41C369.5,64.3,364.4,54.1,352,54.1z M335.8,104c-4.4,0-7-2.5-7-2.5l2.8-15.8
			c2-10.6,7.5-17.6,13.4-17.6c5.2,0,6.8,4.8,6.8,9.3C351.8,88.4,345.3,104,335.8,104z"></path>
                    <path id="dot_4_" d="M393.7,29.8c-5.4,0-9.7,4.3-9.7,9.8c0,5,3.2,8.5,8,8.5h0.2c5.3,0,9.8-3.6,9.9-9.8
			C402.1,33.4,398.8,29.8,393.7,29.8z"></path>
                    <polygon id="i_4_" points="370,115.5 386.9,115.5 398.4,55.5 381.4,55.5 		"></polygon>
                    <path id="f_4_" d="M441.5,55.4h-11.8l0.6-2.8c1-5.8,4.4-10.9,10.1-10.9c3,0,5.4,0.9,5.4,0.9l3.3-13.3c0,0-2.9-1.5-9.2-1.5
			c-6,0-12,1.7-16.6,5.6c-5.8,4.9-8.5,12-9.8,19.2l-0.5,2.8h-7.9l-2.5,12.8h7.9l-9,47.4h16.9l9-47.4h11.7L441.5,55.4z"></path>
                    <path id="y_4_" d="M482.3,55.5c0,0-10.6,26.7-15.3,41.3h-0.2c-0.3-4.7-4.2-41.3-4.2-41.3h-17.8l10.2,55.1c0.2,1.2,0.1,2-0.4,2.8
			c-2,3.8-5.3,7.5-9.2,10.2c-3.2,2.3-6.8,3.8-9.6,4.8l4.7,14.4c3.4-0.7,10.6-3.6,16.6-9.2c7.7-7.2,14.9-18.4,22.2-33.6L500,55.5
			H482.3z"></path>
                </g>
            </g>
        </svg>
    )
}

export default ShopifyLogo;